import React from "react";
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SectionTitle from '../../components/sectiontitle.jsx';
import Section from '../../components/section.jsx';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import LD1 from '../../images/portfolio/lenderdb/lenderdb-1.png';
import LD2 from '../../images/portfolio/lenderdb/lenderdb-2.png';
import LD3 from '../../images/portfolio/lenderdb/lenderdb-3.png';
import LD4 from '../../images/portfolio/lenderdb/lenderdb-4.png';
import LD5 from '../../images/portfolio/lenderdb/lenderdb-5.png';
import LD6 from '../../images/portfolio/lenderdb/lenderdb-6.png';


function PortfolioLenderDB() {
  return (
    <Layout currentPage={[{title:"Portfolio", url:"portfolio"}, {title:"LenderDB", url:"/portfolio/lenderdb"}]}>
      <SEO
        keywords={[`Robert Merki`, `Robmerki`, `Rob Merki`, `@robmerki`,`Merki.ca`]}
        title="LenderDB"
      />

      
      <Section>
        <SectionTitle title="LenderDB" />
      
        <p>
          LenderDB was a project I built with a friend in the financial industry.
        </p>
        <p>
          The purpose was to create a simple listing service for alternative mortgage lenders. This alleviated the constant back-and-forth email chains that currently plague the industry and waste everyone's time.
        </p>

        <p>
          You can see the live site here: <a href="https://LenderDB.com">LenderDB.com</a>
        </p>

        <SectionTitle title="Tech" />
        <p>
          The backend uses Laravel, while the front-end was built in VueJS, InertiaJS, and TailwindCSS.
        </p>
        
        <p>
          It is hosted on a DigitalOcean Droplet and deployed using Github / Laravel Forge.
        </p>
        <SectionTitle title="Images" />

        <Zoom><img className="w-full h-auto" src={LD2}/></Zoom>
        <Zoom><img className="w-full h-auto" src={LD1}/></Zoom>
        <Zoom><img className="w-full h-auto" src={LD3}/></Zoom>
        <Zoom><img className="w-full h-auto" src={LD4}/></Zoom>
        <Zoom><img className="w-full h-auto" src={LD5}/></Zoom>
        <Zoom><img className="w-full h-auto" src={LD6}/></Zoom>

      </Section>
      
    </Layout>
  );
}


export default PortfolioLenderDB;